<template>    
    <v-card dense>
        <v-card-title>
            <s-toolbar dark color="primary" close @close="closePalletExternal()" label="Informacion Camara"></s-toolbar>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <!-- {{ SelectChamberExternal }} -->
                    <v-data-table class="mt-2"
                        :items="ChamberInfo"
                        :headers="headerDetail"
                        dense 
                        item-key="CipID"
                    >
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
                
</template>

<script>
    import _sPackingTunnel from '@/services/FrozenProduction/FrzChamberDispatchTunnels.js';
    import _sChamber from '@/services/FrozenProduction/FrzChamberDispatchTunnelsChamber.js';
    export default{
        props: 
        {
            SelectChamberExternal:{
                default: null,
                type: Object,
            }
        },
        data(){
            return{
                
                ChamberInfo: [],
                headerDetail: [

                    { text: "ID", value: "CipID", width: 20 },
                    { text: "Fecha", value: "DateIncome", width:60},
                    { text: "Hora", value: "HourIncome", width: 60 },
                    { text: "N° Pallet", value: "NPalletGroup", width: 20 },
                    { text: "ID Trazabilidad", value: "TraceabilityID", width: 100},
					{ text: "Cliente", value: "CumNombre", width: 60 },
                    { text: "Marca", value: "BransName", width: 60 },
                    { text: "Variedad", value: "VrtDescription", width: 60 },
                    { text: "Tipo de Corte", value: "TypeCutName", width: 60 },
                    { text: "Presentacion", value: "TypePresentationName", width: 60 },
					{ text: "N° Cajas", value: "NumberBoxes", width: 40 },
					{ text: "Peso Neto", value: "WeightNetBoxes", width: 50 },	
                    // { text: "Estado", value: "TypeEntryName", width: 60 },				
                ],
               
            }
        },
        methods:{
            closePalletExternal(){
                this.$emit("closePalletExternal");
            },
            initialize(){
                let item ={
                    CdtID : this.SelectChamberExternal.CdtID
                }
                console.log('CdtID',item);
                _sPackingTunnel.listPalletExternal(item, this.$fun.getUserID())
                .then((r) => {

                    if (r.status == 200) {
                        this.ChamberInfo = r.data;          
                        console.log('info pallet',this.ChamberInfo);
                    }
                })         
            },

            
        },
        watch:{
        
        },
        created()
        {
           this.initialize();
        }
    }
</script>