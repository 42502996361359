<template>    
    <v-card dense>
        <v-card-title>
            <s-toolbar dark color="primary" close @close="close()" label="Ubicacion de Pallets"></s-toolbar>
        </v-card-title>
        <v-card-text>            
            <v-row>
                <v-col>
                    <s-select
                        :items="itemsTunnel"
                        label="Camara"
                        item-text="CdtDescription"
                        item-value="CdtID"
                        v-model="SelectChamberSend"
                        return-object                                                            
                        @input="clickChamber($event)"></s-select>
                </v-col>
                <v-col>
                    <s-select
                        :items="tunnelsDetails"
                        label="Lado"
                        item-text="SideDescription"
                        item-value="CddID"
                        v-model="SelectSide"
                        return-object                                                            
                        @input="clickSide($event)"></s-select>
                </v-col>
                <v-col cols="auto">
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col lg="2">
                    <s-text v-model="PalletGroupIDSeachs" label="Pallet ID"
                            @input="clickSearck()"
                    ></s-text>                    
                </v-col>
                <v-col cols="auto">
                    <v-btn 
                        fab
                        x-small
                        class="mt-3"
                        color="success"
                        depressed
                        @click="refresh()" >
                        <v-icon >mdi-refresh</v-icon>

                    </v-btn>
                </v-col>
            </v-row>
            <!-- <v-row v-if="hidePositions">
                <v-col >
                    <div>
                        <v-item-group v-model="selectNumberLane" active-class="primary">
                            <div class="text-caption ">Carriles</div>
                            <v-container>
                                <v-row>
                                    <v-col cols="1" lg="1" xs="2" md="3" sm="4" v-for="(item, index) in SelectSide.NumberLanes" 
                                    v-bind:key="index" :value="item">
                                        <v-item v-slot="{ active, toggle }">
                                            <v-chip :color="active ? 'primary' : ''" @click="toggle">
                                                {{ item + SelectSide.NumberStart }}
                                            </v-chip>
                                        </v-item>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-item-group>
                    </div>
                    <div>
                        <v-item-group v-model="selectNumberLevel" active-class="primary">
                            <div class="text-caption ">Nivel</div>
                            <v-container>
                                <v-row>
                                    <v-col cols="1" lg="1" xs="2" md="3" sm="4" v-for="(item, index) in SelectSide.NumberLevels" 
                                    v-bind:key="index" :value="item">
                                        <v-item v-slot="{ active, toggle }">
                                            <v-chip :color="active ? 'primary' : ''" @click="toggle">
                                                {{ Position[index] }}
                                            </v-chip>
                                        </v-item>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-item-group>
                    </div>
                    <div>
                        <v-item-group v-model="selectPositionsLevel" active-class="primary">
                            <div class="text-caption ">Posicion</div>
                            <v-container>
                                <v-row>
                                    <v-col cols="1" lg="1" xs="2" md="3" sm="4" v-for="(item, index) in SelectSide.PositionsLevel" 
                                    v-bind:key="index" :value="item">
                                        <v-item v-slot="{ active, toggle }">
                                            <v-chip :color="active ? 'primary' : ''" @click="toggle">
                                                {{ item }}
                                            </v-chip>
                                        </v-item>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-item-group>
                    </div>
                </v-col>
            </v-row> -->
            <v-row>
                <v-col cols="auto" v-for="(item, indexNumberLanes) in SelectSide.NumberLanes" 
                                            :key="indexNumberLanes" :value="item" >
                    <v-card theme="dark" hover >
                        <!-- <v-container > -->
                            
                                <v-toolbar class="flex-grow-0" dark color="#5edf87" height="30"  rounded >
                                    <v-toolbar-title>{{ 'Carril ' +  parseInt(item + SelectSide.NumberStart)}}</v-toolbar-title>
                                </v-toolbar>
                            
                            <v-card-text>
                                <v-row dense v-for="(nivel, indexNumberLevels) in SelectSide.NumberLevels" 
                                                v-bind:key="indexNumberLevels" :value="nivel">
                                    <v-col  cols="auto"  class="py-1 pe-0">
                                        <v-card hover color="#f4edeb" >
                                            <v-toolbar color="#299ff9" height="30" shaped >
                                                <v-toolbar-title>{{ Position[indexNumberLevels]}}</v-toolbar-title>
                                            </v-toolbar>
                                            
                                            <v-row class="mt-1" dense>
                                                <v-col cols="auto"  v-for="(posicion, indexPositionsLevel) in SelectSide.PositionsLevel" 
                                                    v-bind:key="indexPositionsLevel" :value="posicion">
                                                    <v-card  v-if="SelectChamberSend.Pallest?.filter((x) =>  
                                                                        x.SideID == SelectSide.SideID
                                                                        && x.NumberLanes === indexNumberLanes 
                                                                        && x.NumberLevels === indexNumberLevels
                                                                        && x.PositionsLeve === indexPositionsLevel).length == 1"
                                                        @click="Probar(indexNumberLanes, indexNumberLevels, indexPositionsLevel)"
                                                        color="red accent-2" 
                                                    hover 
                                                    
                                                        >
                                                        <v-card-text>
                                                            {{  posicion }}                                                                        
                                                        </v-card-text>
                                                    </v-card>

                                                    <v-card  v-else
                                                        
                                                        :color="indexNumberLanes == selectNumberLane
                                                                && indexNumberLevels == selectNumberLevel 
                                                                && indexPositionsLevel == selectPositionsLevel ? '#7bc6fe' : '#f0faf7'" 
                                                    hover 
                                                    @click="Probar(indexNumberLanes, indexNumberLevels, indexPositionsLevel)"
                                                        >
                                                        <v-card-text>
                                                            {{  posicion }}                                                                        
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        <!-- </v-container> -->
                    </v-card>                            
                </v-col>
            </v-row>
            
            <v-row class="btn-flotante">
                <v-col cols="12" >                    
                    <v-data-table class="auto"
                        :items="tunnelInfo.Pallets"
                        :headers="headerDetail"
                        dense 
                        show-select
                        single-select
                        item-key="CipID"
                        v-model="SelectPallet"
                        group-by="PalletGroupID"
                        >

                    </v-data-table>
                    <v-btn  
                        absolute
                        small
                        class="white--text " 
                        color="primary"
                        
                        @click="MovePalet()" >
                        <v-icon class="mdi-24px" >mdi-forklift</v-icon>
                    Mover Pallet
                    </v-btn>
                </v-col>
            </v-row>
            <br><br><br><br><br><br><br>
            <v-row v-for="(item ,index) in tunnelInfo.Pallets" v-bind:key="index">
                
                <br><br><br><br>
            </v-row>
        </v-card-text>
        <v-card-actions  >
            
          
           
            <!-- <v-col cols="auto" >
                <v-btn  
                    absolute
                    small
                    class="white--text btn-flotante" 
                    color="primary"
                    
                    @click="MovePalet()" >
                    <v-icon class="mdi-24px" >mdi-forklift</v-icon>
                    Mover Pallet
                </v-btn>
            </v-col> -->
        </v-card-actions>
    </v-card>
                
</template>

<script>
    import _sPackingTunnel from '@/services/FrozenProduction/FrzChamberDispatchTunnels.js';
    import _sChamber from '@/services/FrozenProduction/FrzChamberDispatchTunnelsChamber.js';
import { computed } from 'vue';
    export default{
        props: 
        {
            SelectChamber:{
                default: null,
                type: Object,
            }
        },
        
        data(){
            computed(()  =>{
                
                this.SelectChamberSend = this.SelectChamber;
       
            })
            return{
                tunnelsDetails: [],
                SelectSide: {},
                selectNumberLane: null,
                selectNumberLevel: null,
                Position: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],
                selectPositionsLevel: null,
                tunnelInfo: [],
                headerDetail: [

                    { text: "ID PALLET", value: "PalletGroupID" },
                    { text: "Ubicacion", value: "PalletLocation" },  
                    { text: "Lote", value: "Lote" },
                    { text: "Cliente", value: "CumNombre"},
                    { text: "Marca", value: "BransName"},
                    { text: "N° Cajas", value: "NumberBoxes" },   
                    { text: "Peso Cajas", value: "WeightNetBoxes" },                                  
                    // { text: "Fecha de Ingreso", value: "CipDateBegin" },
                    { text: "Cultivo", value: "TypeCultiveName" },
                    { text: "Variedad", value: "VrtDescription" },
                    { text: "ID trazabilidad", value: "TraceabilityID" },   	
                    { text: "Tipo Corte", value: "TypeCutName" },
                    { text: "Tipo presentacion", value: "TypePresentationName" },
                    { text: "Observación" , value: "Observation" },
                    { text: "E. Final", value: "StateFinalString" },
                ],
                hidePositions: false,
                SelectPallet: [],
                SelectChamberSend:{},
                itemsTunnel: [],
                PalletGroupIDSeachs: ''
            }
        },
        methods:{
            close(){
                this.$emit("closeDetail");
            },
            clickSide(side){
                
            },
            
            Probar(item, nivel, posicion){                
                this.selectNumberLane = item;
                this.selectNumberLevel = nivel;
                this.selectPositionsLevel = posicion;
            },
            clickChamber(chamber)
            {
                if(chamber.CdtID != undefined) 
                {                    
                    // this.SelectSide = {}
                    this.tunnelsDetails = chamber.tunnelsDetails;
                    this.SelectChamberSend = chamber;    
                                  
                }                
            },
            refresh()
            {
                this.$emit("refreshChamber", this.SelectChamber);
                // this.SelectSide = {}                              
                this.tunnelsDetails = [];
             
                this.hidePositions = false;  
                this.selectPositionsLevel = null;
                this.selectNumberLane = null;
                this.selectNumberLevel = null;
                this.getChamber();
                
               
                // this.initialize();
            },
            clickSearck(){
                
                if(this.PalletGroupIDSeachs.length >= 1)
                {
                    let pallets = this.tunnelInfo.Pallets.filter( element => element.NPalletGroup === parseInt(this.PalletGroupIDSeachs));
                    if(pallets.length > 0)
                    {
                        this.tunnelInfo.Pallets = pallets;
                    }                   
                }else{
                    this.initialize();
                }
                
            },
            initialize(){
                this.tunnelInfo = [];
                this.SelectChamberSend.NumberLanes = this.selectNumberLane;
                this.SelectChamberSend.NumberLevels = this.selectNumberLevel;
                this.SelectChamberSend.PositionsLeve = this.selectPositionsLevel;                
                this.SelectChamberSend.SideID = this.SelectSide.SideID;
                if( this.SelectChamberSend.SideID != undefined )
                {
                    _sPackingTunnel.detail(this.SelectChamberSend, this.$fun.getUserID())
                    .then((r) => 
                    {
                        if (r.status == 200)
                        {
                            this.tunnelInfo = r.data;          
                        }
                    })     
                }           
            },

            getChamber(){
                _sChamber.list({CdtWarehouses : 1}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {                        
                        this.itemsTunnel = r.data;                        
                        this.itemsTunnel.forEach( (r, index) => {
                            if(r.CdtID == this.SelectChamber)
                            {
                                this.SelectChamberSend = this.itemsTunnel[index];
                            }
                        })
                        
                        
                    }
                });
            },
            
            MovePalet()
            {
                if(this.SelectPallet.length > 0){
                    let item = {};
                    item = this.SelectPallet[0];
                    if(this.SelectChamber.CdtID != undefined){
                        item.CdtID = this.SelectChamberSend.CdtID;
                    }
                    if(item.CdtID == undefined)
                    {
                        this.$fun.alert("Por Favor Seleccione Camara ", "warning");
                        return;
                    }

                    if(this.SelectSide.SideID == undefined)
                    {
                        this.$fun.alert("Por Favor Seleccione Lado ", "warning");
                        return;
                    }
                    
                    if(this.selectNumberLane == null)
                    {
                        this.$fun.alert("Por Favor Seleccione un Carril ", "warning");
                        return;
                    }
                    if(this.selectNumberLevel == null)
                    {
                        this.$fun.alert("Por Favor Seleccione un Nivel ", "warning");
                        return;
                    }
                    if(this.selectPositionsLevel == null)
                    {
                        this.$fun.alert("Por Favor Seleccione una Posicion ", "warning");
                        return;
                    }
                    item.SideID = this.SelectSide.SideID;
                    item.NumberLanes = this.selectNumberLane;
                    item.NumberLevels = this.selectNumberLevel;
                    item.PositionsLeve = this.selectPositionsLevel;
                    
                    let chambername = "";
                    chambername = this.SelectChamberSend.CdtDescription;  
                    if(chambername.length > 0)              
                        item.PalletLocation = (chambername.substring(0, 3) + ' ' + chambername.slice(-1) + ' ' + 'CARRIL ' + parseInt(this.selectNumberLane + 1 + this.SelectSide.NumberStart)+ '/' +  this.Position[this.selectNumberLevel] + parseInt(this.selectPositionsLevel + 1 ));

                    console.log(item)
                    _sPackingTunnel.moved(item, this.$fun.getUserID())
                    .then( r => {
                        if(r.status == 200)
                        {             
                            this.SelectPallet = [];  
                            this.hidePositions = false;  
                            this.selectPositionsLevel = null;
                            this.selectNumberLane = null;
                            this.selectNumberLevel = null;         
                            this.$fun.alert("Pallet ingresado correctamente", "success");
                            this.getChamber();
                            this.SelectSide = {};
                            this.initialize();
                           
                            
                            // this.$emit("closeDetail");
                        }
                    });
                }else{
                    this.$fun.alert("Seleccione un pallet", "error");
                }

            }
        },
        watch:{
            SelectSide(){
                this.initialize();
                this.hidePositions = true;
            },
            selectNumberLane(){
                this.initialize();
            },
            selectNumberLevel(){
                this.initialize();
            },
            selectPositionsLevel(){
                this.initialize();                
            },
            // tunnelsDetails(){
            //     this.tunnelsDetails = this.SelectChamber.tunnelsDetails;  
            // },
            SelectChamberSend(){
                this.tunnelsDetails = this.SelectChamberSend.tunnelsDetails;                  
                this.SelectSide = this.tunnelsDetails[0];
                this.SelectChamberSend = this.SelectChamberSend;
            }

        },
        
        created()
        {
            // this.tunnelsDetails = this.Sel.SelectChamberSend 
            this.getChamber();
            this.SelectChamberSend = this.SelectChamber;
            this.initialize();
            
        }
    }
</script>
<style>
.btn-flotante {
	font-size: 16px; /* Cambiar el tamaño de la tipografia */
	text-transform: uppercase; /* Texto en mayusculas */
	font-weight: bold; /* Fuente en negrita o bold */
	color: #ffffff; /* Color del texto */
	border-radius: 1px; /* Borde del boton */
	letter-spacing: 2px; /* Espacio entre letras */
	background-color: #299ff9; /* Color de fondo */
	padding: 0px 0px; /* Relleno del boton */
	position: fixed;
	bottom: 40px;
	right: 40px;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
.btn-flotante:hover {
	background-color: #5edf87; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
@media only screen and (max-width: 600px) {
 	.btn-flotante {
		font-size: 14px;
		padding: 12px 20px;
		bottom: 20px;
		right: 20px;
	}
} 

</style>