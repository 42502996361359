<template>
    <!-- <div>
        <v-container>
            <div class="col-md-12 col-sm-12"> -->
                    <v-card denese>
                        <v-row>
                            <div class="col-md-12 col-sm-12">
                                <v-card denese>
                                    <v-row style="margin:auto" >
                                        <v-col cols="5" lg="5" md="5" sm="12">
                                            <s-select-definition 
                                                :def=1450 
                                                label="Almacenes de congelado" 
                                                v-model="CdtWarehouses"
                                                @input="changeChamber()"
                                            >
                                            </s-select-definition>  
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </div>
                        </v-row>
                        
                        <v-row style="margin: auto;">
                            <v-col 
                                cols="6"
                                lg="3"
                                md="4"
                                v-for="item in itemsTunnel"
                                :key="item.PtnID">
                                <v-card dense class="mx-auto">
                                    <v-img lazy-src="https://img.interempresas.net/fotos/2852264.jpeg"
										
										src="https://img.interempresas.net/fotos/2852264.jpeg">
                                        <v-btn style=" margin: auto; display: flex; justify-content: space-evenly; "
                                        :color="item.inColor"
                                            fab
                                            width="100"
                                            height="100"
                                            @click="openModal(item)"
                                            class="mb-8 mt-8">
                                            <span class="white--text text-h4">
                                                <b>
                                                    <h1 style="font-size:20px">
                                                    {{ item.WeightNetBoxesNet + ' TM' }}</h1>
                                                    <!-- {{ item.PtnWeigthNet + ' TM' }}</h1> -->
                                                </b>
                                            </span>
                                        </v-btn>

                                    </v-img>
                                    <v-card style=" margin: auto; display: flex; justify-content: space-evenly; "                                       
                                        :color="item.inColor" outlined >
                                        <h3> {{ item.CdtDescription }}</h3>
                                    </v-card>
                                    <v-card style=" margin: auto; display: flex; justify-content: flex-start; "  color="#8bccf3" outlined>
                                        <v-row>
                                            <v-col >
                                                <h3 class="ml-1"> Capacidad: </h3>
                                            </v-col>                                            
                                            <v-col >
                                                <v-chip small  color="#5fbbf3" style="font-size: 14px; font-weight: bold;">
                                                    {{ item.AvailablePositions + item.inPalletUsage }}
                                                </v-chip>                      
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    <v-card style=" margin: auto; display: flex; justify-content: flex-start; "  color="#8bccf3" outlined>
                                        <v-row>
                                            <v-col >
                                                <h3 class="ml-1"> Pallets: </h3>                                                
                                            </v-col>
                                            <v-col >
                                                <v-chip  small  :color="item.inColor" style="font-size: 14px; font-weight: bold;" >{{ item.inPalletUsage }}</v-chip>                                                
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    <v-card style=" margin: auto; display: flex; justify-content: flex-start; "  color="#8bccf3" outlined>
                                        <v-row>
                                            <v-col >
                                                <h3 class="ml-1"> Disponibles: </h3>                                                
                                            </v-col>
                                            <v-col>
                                                <v-chip small  color="#5fbbf3" style="background: #45ada1; font-size: 14px; font-weight: bold;">{{ item.AvailablePositions }}</v-chip>                                                
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-card>
                            </v-col>
                        </v-row>
                    
                <!-- </div> -->
                <v-dialog 
                    persistent
                    fullscreen
                    v-model="dialogChamberDetail"
                    v-if="dialogChamberDetail">
                        <chamber-detail :SelectChamber="SelectChamber" @closeDetail="closedialogChamberDetail()" @refreshChamber="refreshChamber(SelectChamber)"></chamber-detail>
                </v-dialog>
                <v-dialog
                    persistent
                    fullscreen
                    v-model="dialogChamberExternal"
                    v-if="dialogChamberExternal"
                >
                    <chamber-external :SelectChamberExternal="SelectChamberExternal" @closePalletExternal="closePalletExternal()"></chamber-external>
                </v-dialog>
            </v-card>
        <!-- </v-container>
    </div> -->
</template>

<script>

    import _sChamber from '@/services/FrozenProduction/FrzChamberDispatchTunnelsChamber.js';
    import ChamberDetail from './FrzChamberPalletInTunnelDetail.vue';
    import ChamberExternal from './FrzChamberPalletTunnelExternal.vue';

    export default {
        components:{
            ChamberDetail,
            ChamberExternal
        },
        data(){
            return{
                itemsTunnel: [],
                dialogChamberDetail: false,
                SelectChamber: {},
                CdtWarehouses: 0,
                dialogChamberExternal: false,
                SelectChamberExternal:{},
            }
        },methods:
        {
            closedialogChamberDetail()
            {
                this.dialogChamberDetail = false;
                this.changeChamber();
            },
            closePalletExternal(){
                this.dialogChamberExternal = false;
            },
            openModal(item){
                
                if (this.CdtWarehouses != 1){
                    this.dialogChamberExternal = true
                    this.SelectChamberExternal = item
                    
                }else{
                    this.dialogChamberDetail = true;
                    this.SelectChamber = item;
                }
            },
            initialize(){
                _sChamber.list({}, this.$fun.getUserID()).then((r) => {
                    if(r.status == 200){
                        this.itemsTunnel = r.data;
                        
                    }
                })
            },
            changeChamber() {
                
                
                _sChamber.list({CdtWarehouses : this.CdtWarehouses}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        r.data.forEach(element => {
                            element.ParihuelaUsagePorcentaje = (100 * element.inPalletUsage) /  parseInt(element.CdtCapacity);
                            
                            if(element.ParihuelaUsagePorcentaje >= 100){
								element.inColor = 'error'
							}

							//Tunnel por llenarse
							if(element.ParihuelaUsagePorcentaje >= 80 && element.ParihuelaUsagePorcentaje < 100){
								element.inColor = 'warning'
							}

							if(element.ParihuelaUsagePorcentaje > 35 && element.ParihuelaUsagePorcentaje <= 79){
								element.inColor = 'info'
							}

							if(element.ParihuelaUsagePorcentaje <= 35){
								element.inColor = 'success'
							}
                        });
                        this.itemsTunnel = r.data;  
                                         
                    }
                });
            },
            refreshChamber: function(item) {
            
                
                this.initialize();
                this.itemsTunnel.forEach( element => {
                    if(element.CdtID == item.CdtID) 
                    {
                        this.SelectChamber = element;
                    }
                });

            }
        },
        created(){
            this.initialize();
        },
        watch:{
            CdtWarehouses(){
                this.initialize();
            }
        }
    }
</script>