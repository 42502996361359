import Service from "../Service"

const resource = "chamberdispatchtunnels/"

export default {

    // save(cmp, requestID){
    //     return Service.post(resource + "save", cmp,{
    //         params: {requestID: requestID},
    //     });
    // },
    // pagination(dtr, requestID){
    //     return Service.post(resource + "pagination", dtr,{
    //         params:{requestID: requestID},
    //     });
    // },
    list(fas, requestID) {
        return Service.post(resource + "list", fas, {
          params: { requestID: requestID },
        });
    },
    detail(obj, requestID) {
        return Service.post(resource + "detail", obj, {
          params: { requestID: requestID },
        });
    },
    moved(obj, requestID) {
        return Service.post(resource + "moved", obj, {
          params: { requestID: requestID },
        });
    },
    listPalletExternal(obj, requestID) {
        return Service.post(resource + "listPalletExternal", obj, {
          params: { requestID: requestID },
        });
    },
    
}